app.service('uploadImage', function (modal, loading) {

   	function processFileUploadChangeEvent(event) {
   		const target = event.target;
		if (target.tagName === "INPUT" && target.type === "file") {
			const files = target.files;
			for (let file of files) {
				if (file.name.toLowerCase().endsWith(".gif")) {
					toastr.warning(
						'Wgrany plik GIF zostanie automatycznie przekonwertowany na format WEBP',
						'Jeśli zależy Ci na animowanej grafice, wgraj odpowiedni plik w formacie WEBP',
						{ timeOut: 0, extendedTimeOut: 0 }
					);
					break;
				}
			}
		}
   	}

    function open({ url, $span, data = {}, callback = null }) {
        eval('data='+data);
        modal.generate({
            id: 'modal-add-ajax-photo',
            header: 'Dodawanie zdjęcia',
            content: `
                <form id="photo-form" action="${url}" method="post" enctype="multipart/form-data">
					<input name="image" type="file" />
					<div>
                        <button class="js-upload-photo">Zapisz</button>
                    </div>
				</form>
            `,
            callbackOpen: function () {
                $('#photo-form').ajaxForm({
                    beforeSubmit: function () {
                        loading.start();
                    },
                    data: data,
                    success: function (json) {
                        loading.stop();
                        if (json.status == 'SUCCESS') {
                            modal.close();
                            if ($span.length) {
                                $span.empty().html('<img src="' + json.url + '"/>');
                            }
                            if (callback) {
                                eval(callback+'()');
                            }
                            toastr.success('Dodawanie obrazka udane');
                        } else if (json.status == 'FAIL') { 
                            toastr.error(
                                json.messages.join("<br />")
                            );
                        } else {
                            toastr.error("Błąd poczas zapisywania zdjęcia.");
                        }
                    },
                    error: function () {
                        loading.stop();
                        toastr.error("Błąd poczas zapisywania zdjęcia.");
                    }
                });
            }
        });

	}

	return {
		open, processFileUploadChangeEvent
	}
});
